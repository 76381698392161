/* General settings related to HbbTV */
.sudoku-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 1280px;
  height: 720px;
  color: white;
  background-image: url("/src/images/games/sudoku/arkaplan.png");
  display: flex;
  flex-direction: column;

  .main-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;

    .left-panel {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 520px;
      margin-left: 20px;

      .notification {
        font-size: 18px;
        font-weight: bold;
        color: #6c3807;
        background-image: url("/src/images/games/sudoku/infobant.png");
        background-position: center;
        background-repeat: no-repeat;
        text-align: center;
        width: 100%;
        height: 100px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
      }

      .notification-text {
        margin-top: 22px;
        height: 50px;
        width: 300px;
      }

      .button-hint-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
      }

      .sudoku-container {
        position: relative;
        width: 405px;
        height: 405px;
        margin: 0 auto;
      }

      .button-back {
        padding-top: 10px;
        margin-left: 55px;
      }

      .hint-text {
        font-size: 28px;
        font-weight: bold;
        text-align: left;
        background-image: url("/src/images/games/sudoku/ipucu-1-button.png");
        background-position: center;
        background-repeat: no-repeat;
        color: #6c3807;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .sudoku-cell {
    width: 45px;
    height: 45px;
    position: absolute;
    border: 3px solid #514c33;
    background-color: #fef47f;
    font-size: 30px;
    font-weight: bold;
    color: #4284c2;
    text-align: center;
    line-height: 45px;
  }

  .sudoku-cell:nth-child(1) {
    top: 0;
    left: 0;
  }

  .sudoku-cell:nth-child(2) {
    top: 0;
    left: 45px; /* Updated */
  }

  .sudoku-cell:nth-child(3) {
    top: 0;
    left: 90px; /* Updated */
  }

  .sudoku-cell:nth-child(4) {
    top: 0;
    left: 135px; /* Updated */
  }

  .sudoku-cell:nth-child(5) {
    top: 0;
    left: 180px; /* Updated */
  }

  .sudoku-cell:nth-child(6) {
    top: 0;
    left: 225px; /* Updated */
  }

  .sudoku-cell:nth-child(7) {
    top: 0;
    left: 270px; /* Updated */
  }

  .sudoku-cell:nth-child(8) {
    top: 0;
    left: 315px; /* Updated */
  }

  .sudoku-cell:nth-child(9) {
    top: 0;
    left: 360px; /* Updated */
  }

  .sudoku-cell:nth-child(10) {
    top: 45px; /* Updated */
    left: 0;
  }

  .sudoku-cell:nth-child(11) {
    top: 45px; /* Updated */
    left: 45px; /* Updated */
  }

  .sudoku-cell:nth-child(12) {
    top: 45px; /* Updated */
    left: 90px; /* Updated */
  }

  .sudoku-cell:nth-child(13) {
    top: 45px; /* Updated */
    left: 135px; /* Updated */
  }

  .sudoku-cell:nth-child(14) {
    top: 45px; /* Updated */
    left: 180px; /* Updated */
  }

  .sudoku-cell:nth-child(15) {
    top: 45px; /* Updated */
    left: 225px; /* Updated */
  }

  .sudoku-cell:nth-child(16) {
    top: 45px; /* Updated */
    left: 270px; /* Updated */
  }

  .sudoku-cell:nth-child(17) {
    top: 45px; /* Updated */
    left: 315px; /* Updated */
  }

  .sudoku-cell:nth-child(18) {
    top: 45px; /* Updated */
    left: 360px; /* Updated */
  }

  .sudoku-cell:nth-child(19) {
    top: 90px; /* Updated */
    left: 0;
  }

  .sudoku-cell:nth-child(20) {
    top: 90px; /* Updated */
    left: 45px; /* Updated */
  }

  .sudoku-cell:nth-child(21) {
    top: 90px; /* Updated */
    left: 90px; /* Updated */
  }

  .sudoku-cell:nth-child(22) {
    top: 90px; /* Updated */
    left: 135px; /* Updated */
  }

  .sudoku-cell:nth-child(23) {
    top: 90px; /* Updated */
    left: 180px; /* Updated */
  }

  .sudoku-cell:nth-child(24) {
    top: 90px; /* Updated */
    left: 225px; /* Updated */
  }

  .sudoku-cell:nth-child(25) {
    top: 90px; /* Updated */
    left: 270px; /* Updated */
  }

  .sudoku-cell:nth-child(26) {
    top: 90px; /* Updated */
    left: 315px; /* Updated */
  }

  .sudoku-cell:nth-child(27) {
    top: 90px; /* Updated */
    left: 360px; /* Updated */
  }

  .sudoku-cell:nth-child(28) {
    top: 135px; /* Updated */
    left: 0;
  }

  .sudoku-cell:nth-child(29) {
    top: 135px; /* Updated */
    left: 45px; /* Updated */
  }

  .sudoku-cell:nth-child(30) {
    top: 135px; /* Updated */
    left: 90px; /* Updated */
  }

  .sudoku-cell:nth-child(31) {
    top: 135px; /* Updated */
    left: 135px; /* Updated */
  }

  .sudoku-cell:nth-child(32) {
    top: 135px; /* Updated */
    left: 180px; /* Updated */
  }

  .sudoku-cell:nth-child(33) {
    top: 135px; /* Updated */
    left: 225px; /* Updated */
  }

  .sudoku-cell:nth-child(34) {
    top: 135px; /* Updated */
    left: 270px; /* Updated */
  }

  .sudoku-cell:nth-child(35) {
    top: 135px; /* Updated */
    left: 315px; /* Updated */
  }

  .sudoku-cell:nth-child(36) {
    top: 135px; /* Updated */
    left: 360px; /* Updated */
  }

  .sudoku-cell:nth-child(37) {
    top: 180px; /* Updated */
    left: 0;
  }

  .sudoku-cell:nth-child(38) {
    top: 180px; /* Updated */
    left: 45px; /* Updated */
  }

  .sudoku-cell:nth-child(39) {
    top: 180px; /* Updated */
    left: 90px; /* Updated */
  }

  .sudoku-cell:nth-child(40) {
    top: 180px; /* Updated */
    left: 135px; /* Updated */
  }

  .sudoku-cell:nth-child(41) {
    top: 180px; /* Updated */
    left: 180px; /* Updated */
  }

  .sudoku-cell:nth-child(42) {
    top: 180px; /* Updated */
    left: 225px; /* Updated */
  }

  .sudoku-cell:nth-child(43) {
    top: 180px; /* Updated */
    left: 270px; /* Updated */
  }

  .sudoku-cell:nth-child(44) {
    top: 180px; /* Updated */
    left: 315px; /* Updated */
  }

  .sudoku-cell:nth-child(45) {
    top: 180px; /* Updated */
    left: 360px; /* Updated */
  }

  .sudoku-cell:nth-child(46) {
    top: 225px; /* Updated */
    left: 0;
  }

  .sudoku-cell:nth-child(47) {
    top: 225px; /* Updated */
    left: 45px; /* Updated */
  }

  .sudoku-cell:nth-child(48) {
    top: 225px; /* Updated */
    left: 90px; /* Updated */
  }

  .sudoku-cell:nth-child(49) {
    top: 225px; /* Updated */
    left: 135px; /* Updated */
  }

  .sudoku-cell:nth-child(50) {
    top: 225px; /* Updated */
    left: 180px; /* Updated */
  }

  .sudoku-cell:nth-child(51) {
    top: 225px; /* Updated */
    left: 225px; /* Updated */
  }

  .sudoku-cell:nth-child(52) {
    top: 225px; /* Updated */
    left: 270px; /* Updated */
  }

  .sudoku-cell:nth-child(53) {
    top: 225px; /* Updated */
    left: 315px; /* Updated */
  }

  .sudoku-cell:nth-child(54) {
    top: 225px; /* Updated */
    left: 360px; /* Updated */
  }

  .sudoku-cell:nth-child(55) {
    top: 270px; /* Updated */
    left: 0;
  }

  .sudoku-cell:nth-child(56) {
    top: 270px; /* Updated */
    left: 45px; /* Updated */
  }

  .sudoku-cell:nth-child(57) {
    top: 270px; /* Updated */
    left: 90px; /* Updated */
  }

  .sudoku-cell:nth-child(58) {
    top: 270px; /* Updated */
    left: 135px; /* Updated */
  }

  .sudoku-cell:nth-child(59) {
    top: 270px; /* Updated */
    left: 180px; /* Updated */
  }

  .sudoku-cell:nth-child(60) {
    top: 270px; /* Updated */
    left: 225px; /* Updated */
  }

  .sudoku-cell:nth-child(61) {
    top: 270px; /* Updated */
    left: 270px; /* Updated */
  }

  .sudoku-cell:nth-child(62) {
    top: 270px; /* Updated */
    left: 315px; /* Updated */
  }

  .sudoku-cell:nth-child(63) {
    top: 270px; /* Updated */
    left: 360px; /* Updated */
  }

  .sudoku-cell:nth-child(64) {
    top: 315px; /* Updated */
    left: 0;
  }

  .sudoku-cell:nth-child(65) {
    top: 315px; /* Updated */
    left: 45px; /* Updated */
  }

  .sudoku-cell:nth-child(66) {
    top: 315px; /* Updated */
    left: 90px; /* Updated */
  }

  .sudoku-cell:nth-child(67) {
    top: 315px; /* Updated */
    left: 135px; /* Updated */
  }

  .sudoku-cell:nth-child(68) {
    top: 315px; /* Updated */
    left: 180px; /* Updated */
  }

  .sudoku-cell:nth-child(69) {
    top: 315px; /* Updated */
    left: 225px; /* Updated */
  }

  .sudoku-cell:nth-child(70) {
    top: 315px; /* Updated */
    left: 270px; /* Updated */
  }

  .sudoku-cell:nth-child(71) {
    top: 315px; /* Updated */
    left: 315px; /* Updated */
  }

  .sudoku-cell:nth-child(72) {
    top: 315px; /* Updated */
    left: 360px; /* Updated */
  }

  .sudoku-cell:nth-child(73) {
    top: 360px; /* Updated */
    left: 0;
  }

  .sudoku-cell:nth-child(74) {
    top: 360px; /* Updated */
    left: 45px; /* Updated */
  }

  .sudoku-cell:nth-child(75) {
    top: 360px; /* Updated */
    left: 90px; /* Updated */
  }

  .sudoku-cell:nth-child(76) {
    top: 360px; /* Updated */
    left: 135px; /* Updated */
  }

  .sudoku-cell:nth-child(77) {
    top: 360px; /* Updated */
    left: 180px; /* Updated */
  }

  .sudoku-cell:nth-child(78) {
    top: 360px; /* Updated */
    left: 225px; /* Updated */
  }

  .sudoku-cell:nth-child(79) {
    top: 360px; /* Updated */
    left: 270px; /* Updated */
  }

  .sudoku-cell:nth-child(80) {
    top: 360px; /* Updated */
    left: 315px; /* Updated */
  }

  .sudoku-cell:nth-child(81) {
    top: 360px; /* Updated */
    left: 360px; /* Updated */
  }

  .sudoku-cell:nth-child(3n+1) {
    border-left: 6px solid #9a0000;
  }

  .sudoku-cell:nth-child(3n) {
    border-right: 6px solid #9a0000;
  }

  .sudoku-cell:nth-child(n+1):nth-child(-n+9),
  .sudoku-cell:nth-child(n+28):nth-child(-n+36),
  .sudoku-cell:nth-child(n+55):nth-child(-n+63) {
    border-top: 6px solid #9a0000;
  }

  .sudoku-cell:nth-child(n+19):nth-child(-n+27),
  .sudoku-cell:nth-child(n+46):nth-child(-n+54),
  .sudoku-cell:nth-child(n+73):nth-child(-n+81) {
    border-bottom: 6px solid #9a0000;
  }

  .initial-clue {
    color: #377400 !important;
  }

  .hint-revealed {
    color: #f37109;
  }
}
