.kelime-tahmin-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 1280px;
  height: 720px;
  color: white;
  background-image: url("/src/images/games/kelime-tahmin/bg0.png");

  #question {
    position: absolute;
    margin-top: 10px;
    top: 48px;
    left: 50%;
    transform: translateX(-50%);
    border-spacing: 0;
    font-size: 32px;
    color: #fff;
    width: 1000px;
    text-align: center;
  }

  #timer {
    position: absolute;
    display: inline-block;
    top: 20px;
    right: 24px;
    background-color: #02a023;
    padding: 4px;
    font-weight: 700;
    color: #fff;
    font-size: 24px;
    width: 64px;
    text-align: center;
    border: 1px solid #c7f206;
  }

  #letter-count {
    position: absolute;
    display: inline-block;
    top: 64px;
    right: 43px;
    background-color: #fff;
    border: 3px solid #089a09;
    width: 32px;
    font-weight: 700;
    color: #089a09;
    font-size: 24px;
    text-align: center;
  }

  #go-choices {
    display: none;
    position: absolute;
    top: 532px;
    left: 50%;
    transform: translateX(-50%);
  }

  .button-green {
    width: 24px;
    height: 24px;
    display: inline-block;
    border: 2px solid #0f0;
    background-color: #02a123;
    border-radius: 50%;
  }

  .button-blue {
    width: 24px;
    height: 24px;
    display: inline-block;
    border: 2px solid #0fadb8;
    background-color: #022ca1;
    border-radius: 50%;
  }

  .solo {
    position: absolute;
    top: 50%;
  }

  .button-text {
    display: inline-block;
    line-height: 28px;
    font-size: 24px;
    margin-left: 8px;
    color: #fff;
    font-weight: 700;
  }

  .word-area {
    position: relative;
    top: 110px;
  }

  .block-table {
    border-spacing: 0;
    margin: auto;
    color: black;

    td {
      border: 2px solid #000;
      width: 54px;
      height: 66px;
      background-color: #00cc2f;
      font-size: 48px;
      text-align: center;
      font-weight: 700;
    }

    .open {
      background-color: #fff;
    }
  }

  .letter-area {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 300px;
  }

  #letters {
    td {
      background-color: #fff;
      display: inline-block;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      line-height: 32px;
      margin: 0 3px;
      font-weight: 700;
      position: relative;
      font-size: 24px;
      border: 2px solid transparent;
      color: black;
    }

    .active {
      border: 2px solid #0f0;
      box-shadow: 0 0 18px #fff;
    }

    .bg-green {
      background-color: #07ae2a !important;
    }

    .bg-red {
      background-color: #ff0101;
    }
  }

  .font-size-0 {
    font-size: 0 !important;
  }

  .font-size-48 {
    font-size: 48px !important;
  }

  .count-text {
    padding-top: 10px;
    font-size: 26px;
    text-align: center;
  }

  .answer-choices {
    position: absolute;
    z-index: 1;
    width: 1280px;
    height: 720px;
    top: 0;
  }

  .option {
    background-color: limegreen;
    display: inline-block;
    padding: 8px;
    margin: 8px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 36px;
    position: relative;
  }

  .option-wrapper {
    position: relative;
    top: 290px;
    text-align: center;
  }

  .selected-option {
    box-shadow: 0 0 18px #fff;
    border: 2px solid #fff;
  }

  .answer-wrapper {
    position: absolute;
    top: 500px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 24px;
  }

  #answer {
    background-color: #02a023;
    padding: 8px 6px;
    box-shadow: 0 0 18px #fff;
    border-radius: 4px;
    margin-left: 10px;
    font-weight: 700;
  }
}
