.dama-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 1280px;
  height: 720px;
  display: flex;
  justify-content: space-between;
  color: white;
  background-image: url("/src/images/games/dama/arkaplan.png");

  .left-column,
  .right-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 100px;
  }

  .dama-container {
    position: relative;
    width: 480px;
    height: 480px;
    margin: 0 auto;
    border: 5px solid #14132b;
    box-shadow: 0 0 0 5px #fff;
  }

  .dama-cell {
    width: 60px;
    height: 60px;
    position: absolute;
    background-color: #fff;
    text-align: center;

    &.highlight {
      background-color: #ffeb3b !important;
    }

    &.highlight-square {
      background-color: #b4ade6 !important;
    }
  }

  .dama-cell:nth-child(1) {
    top: 0;
    left: 0;
  }

  .dama-cell:nth-child(2) {
    top: 0;
    left: 60px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(3) {
    top: 0;
    left: 120px;
  }

  .dama-cell:nth-child(4) {
    top: 0;
    left: 180px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(5) {
    top: 0;
    left: 240px;
  }

  .dama-cell:nth-child(6) {
    top: 0;
    left: 300px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(7) {
    top: 0;
    left: 360px;
  }

  .dama-cell:nth-child(8) {
    top: 0;
    left: 420px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(9) {
    top: 60px;
    left: 0;
    background-color: #14132b;
  }

  .dama-cell:nth-child(10) {
    top: 60px;
    left: 60px;
  }

  .dama-cell:nth-child(11) {
    top: 60px;
    left: 120px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(12) {
    top: 60px;
    left: 180px;
  }

  .dama-cell:nth-child(13) {
    top: 60px;
    left: 240px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(14) {
    top: 60px;
    left: 300px;
  }

  .dama-cell:nth-child(15) {
    top: 60px;
    left: 360px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(16) {
    top: 60px;
    left: 420px;
  }

  .dama-cell:nth-child(17) {
    top: 120px;
    left: 0;
  }

  .dama-cell:nth-child(18) {
    top: 120px;
    left: 60px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(19) {
    top: 120px;
    left: 120px;
  }

  .dama-cell:nth-child(20) {
    top: 120px;
    left: 180px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(21) {
    top: 120px;
    left: 240px;
  }

  .dama-cell:nth-child(22) {
    top: 120px;
    left: 300px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(23) {
    top: 120px;
    left: 360px;
  }

  .dama-cell:nth-child(24) {
    top: 120px;
    left: 420px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(25) {
    top: 180px;
    left: 0;
    background-color: #14132b;
  }

  .dama-cell:nth-child(26) {
    top: 180px;
    left: 60px;
  }

  .dama-cell:nth-child(27) {
    top: 180px;
    left: 120px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(28) {
    top: 180px;
    left: 180px;
  }

  .dama-cell:nth-child(29) {
    top: 180px;
    left: 240px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(30) {
    top: 180px;
    left: 300px;
  }

  .dama-cell:nth-child(31) {
    top: 180px;
    left: 360px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(32) {
    top: 180px;
    left: 420px;
  }

  .dama-cell:nth-child(33) {
    top: 240px;
    left: 0;
  }

  .dama-cell:nth-child(34) {
    top: 240px;
    left: 60px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(35) {
    top: 240px;
    left: 120px;
  }

  .dama-cell:nth-child(36) {
    top: 240px;
    left: 180px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(37) {
    top: 240px;
    left: 240px;
  }

  .dama-cell:nth-child(38) {
    top: 240px;
    left: 300px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(39) {
    top: 240px;
    left: 360px;
  }

  .dama-cell:nth-child(40) {
    top: 240px;
    left: 420px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(41) {
    top: 300px;
    left: 0;
    background-color: #14132b;
  }

  .dama-cell:nth-child(42) {
    top: 300px;
    left: 60px;
  }

  .dama-cell:nth-child(43) {
    top: 300px;
    left: 120px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(44) {
    top: 300px;
    left: 180px;
  }

  .dama-cell:nth-child(45) {
    top: 300px;
    left: 240px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(46) {
    top: 300px;
    left: 300px;
  }

  .dama-cell:nth-child(47) {
    top: 300px;
    left: 360px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(48) {
    top: 300px;
    left: 420px;
  }

  .dama-cell:nth-child(49) {
    top: 360px;
    left: 0;
  }

  .dama-cell:nth-child(50) {
    top: 360px;
    left: 60px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(51) {
    top: 360px;
    left: 120px;
  }

  .dama-cell:nth-child(52) {
    top: 360px;
    left: 180px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(53) {
    top: 360px;
    left: 240px;
  }

  .dama-cell:nth-child(54) {
    top: 360px;
    left: 300px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(55) {
    top: 360px;
    left: 360px;
  }

  .dama-cell:nth-child(56) {
    top: 360px;
    left: 420px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(57) {
    top: 420px;
    left: 0;
    background-color: #14132b;
  }

  .dama-cell:nth-child(58) {
    top: 420px;
    left: 60px;
  }

  .dama-cell:nth-child(59) {
    top: 420px;
    left: 120px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(60) {
    top: 420px;
    left: 180px;
  }

  .dama-cell:nth-child(61) {
    top: 420px;
    left: 240px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(62) {
    top: 420px;
    left: 300px;
  }

  .dama-cell:nth-child(63) {
    top: 420px;
    left: 360px;
    background-color: #14132b;
  }

  .dama-cell:nth-child(64) {
    top: 420px;
    left: 420px;
  }

  .modal-container {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 1280px;
    height: 720px;
    z-index: 1;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 1280px;
    height: 720px;
    background-color: #000000ad;
    z-index: 1;
  }

  .rules {
    position: absolute;
    z-index: 2;
    top: 33%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .notification {
    font-size: 22px;
    font-weight: bold;
    color: black;
    background-image: url("/src/images/games/dama/infobant.png");
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    width: 100%;
    height: 100px;
    margin-top: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .notification-text {
    margin-top: 36px;
    height: 65px;
    width: 350px;
  }

  .scoreboard {
    font-size: 36px;
    color: white;
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .piece {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .button-container {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .button {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .button img {
    width: 227px;
    height: 98px;
    object-fit: none;
  }

  .button-text,
  .button-text-back {
    font-size: 16px;
    padding-left: 10px;
  }
}
