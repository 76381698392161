@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100200300400500600700800&display=swap");

body {
  position: relative;
  font-family: "Open Sans", sans-serif;
  background-color: #1a0130;
  color: #fff;
}

.top-content {
  padding-top: 24px;
  overflow: auto;
  color: #a3a3a3;
  background-color: #11011e;

  .left-box {
    float: left;
    margin-right: 10px;
    height: 45px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .right-box {
    height: 45px;
    float: right;
    margin-right: 36px;
    line-height: 45px;

    i {
      margin-right: 4px;
    }
  }

  .top-logo {
    height: 45px;
    margin-right: 24px;
    margin-left: 48px;
    padding-left: 0;
  }

  .weather-wrapper {
    line-height: 45px;
    background-color: #19171c;
    border-radius: 5px;

    .city {
      line-height: 1.2;
      text-align: center;
      margin-top: 2px;
    }
  }

  .currency-wrapper {
    line-height: 45px;
    background-color: #19171c;
    border-radius: 5px;
    width: 120px;
  }

  .game-score {
    line-height: 45px;
    background-color: #19171c;
    border-radius: 5px;
  }

  .exit-button {
    margin-right: 56px;
    color: #df2b22;
  }
}

.header-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: auto;
  padding-left: 48px;
  background-color: #11011e;
  line-height: 1;

  .header-item.active {
    background-color: #1a0130;
  }

  .header-item {
    float: left;
    margin-right: 54px;
    padding: 10px 24px;
    border-radius: 5px;

    &.selected {
      box-shadow: 0 0 0 2px #fff;
    }

    i {
      margin-right: 8px;
    }
  }
}

.left-content {
  float: left;
  width: 832px;
  margin-top: 20px;
  margin-left: 48px;
  overflow: hidden;
  height: 555px;

  .banner-wrapper {
    height: 265px;
    width: 790px;

    .banner-program {
      border-radius: 15px;
      margin-left: 2px;
      margin-top: 2px;
      width: 100%;
      height: 100%;

      &.selected {
        box-shadow: 0 0 0 2px #fff;
      }
    }
  }
}

.right-content {
  float: right;
  width: 400px;
  margin-top: 24px;

  .banner-product-wrapper {
    margin-left: 16px;
    width: 336px;
    height: 280px;
  }
}

.program-series-title {
  margin-top: 20px;
  margin-bottom: 18px;
  position: relative;
  z-index: 1;

  &::after {
    position: absolute;
    content: "";
    right: 0;
    top: 36px;
    height: 160px;
    width: 160px;
    background: linear-gradient(to right, #1a013013, #1a0130);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

.weather-forecast {
  clear: both;
  display: table;
  width: 100%;
  font-size: 14px;
  line-height: 1;

  span {
    margin-left: 5px;
    display: table-cell;
    vertical-align: middle;
    max-width: 126px;
    line-height: 1;
  }

  img {
    width: 24px;
    display: table-cell;
    vertical-align: middle;
  }
}

.program.selected {
  img {
    box-shadow: 0 0 0 2px #fff;
  }
}

.program-series {
  white-space: nowrap;

  .program {
    display: inline-block;
    margin-right: 22px;
    margin-left: 2px;
    background-color: #11011e;
    padding-bottom: 4px;
    border-radius: 10px;

    img {
      border-radius: 10px;
      height: 144px;
      width: 240px;
    }

    .program-title {
      margin-top: 8px;
      width: 240px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 4px;
    }
  }
}

.news-title-top {
  margin-left: 12px;
  margin-top: 2px;

  .news-date {
    float: left;
  }

  .news-back-wrapper {
    float: right;

    .news-back {
      background-color: #11011e;
      padding: 8px 16px;
      border-radius: 16px;

      &.selected {
        box-shadow: 0 0 0 2px #fff;
      }
    }

    i {
      margin-right: 8px;
    }
  }

  .title-desc {
    clear: both;
    padding-top: 24px;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
  }
}

.news-description {
  font-size: 16px;
  line-height: 1.4;
  text-align: justify;

  .news-arrows {
    display: none;
  }
}

.news-series {
  margin-top: 24px;

  .news {
    display: inline-block;
    margin-right: 26px;
    width: 240px;
    margin-bottom: 24px;
    margin-left: 2px;
    background-color: #11011e;
    padding-bottom: 4px;

    img {
      width: 240px;
      height: 144px;
      border-radius: 10px;
    }

    .news-title {
      margin-top: 12px;
      white-space: normal;
      height: 38px;
      overflow: hidden;
    }
  }
}

.news.selected {
  img {
    box-shadow: 0 0 0 2px #fff;
  }
}

.streaming-list-header {
  position: relative;
  z-index: 1;
  background-color: #1a0130;
  padding-bottom: 12px;
}

.streaming-list-title {
  display: inline-block;
  width: 48%;
}

.streaming-buttons {
  display: inline-block;
  width: 48%;
  text-align: right;
  margin-top: 2px;

  .prev-day {
    display: inline-block;
    background-color: #11011e;
    border-radius: 5px;
    padding: 6px 10px;
    margin-right: 12px;

    i {
      margin-right: 8px;
    }

    &.selected {
      box-shadow: 0 0 0 2px #fff;
    }
  }

  .next-day {
    display: inline-block;
    background-color: #11011e;
    border-radius: 5px;
    padding: 6px 10px;

    i {
      margin-left: 8px;
    }

    &.selected {
      box-shadow: 0 0 0 2px #fff;
    }
  }
}

.streaming-list {
  .program {
    display: inline-block;
    font-weight: 300;
    margin: 2px 26px 16px 2px;
    background-color: #11011e;
    padding-bottom: 4px;
    border-radius: 10px;

    img {
      border-radius: 10px;
      width: 240px;
      height: 144px;
    }

    .program-title {
      margin-top: 8px;
      margin-bottom: 4px;
      max-width: 240px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 4px;
    }

    .program-date {
      margin-left: 4px;
    }
  }
}

.featured-news-wrapper {
  height: 289px;
  width: 796px;

  .featured-news {
    width: 100%;
    height: 100%;
    border-radius: 10px;

    &.selected {
      box-shadow: 0 0 0 2px #fff;
    }
  }
}

.tvshows-title {
  margin-bottom: 10px;
}

.tvshows-list {
  white-space: nowrap;
  margin-bottom: 16px;

  .program {
    display: inline-block;
    margin: 2px 26px 14px 2px;
    font-weight: 300;
    background-color: #11011e;
    padding-bottom: 4px;
    border-radius: 10px;

    img {
      border-radius: 10px;
      width: 240px;
      height: 144px;
    }

    .program-title {
      margin-top: 8px;
      margin-bottom: 4px;
      margin-left: 4px;
    }
  }
}

.program-cards {
  white-space: nowrap;
  margin-top: 22px;

  .program {
    display: inline-block;
    margin-right: 28px;
    font-weight: 300;
    background-color: #11011e;
    padding-bottom: 4px;
    border-radius: 10px;

    img {
      border-radius: 10px;
      margin-top: 2px;
      margin-left: 2px;
      width: 240px;
      height: 144px;
    }

    .program-title {
      margin-top: 8px;
      margin-bottom: 4px;
      margin-left: 4px;
    }
  }
}

.program-clips-title {
  margin-bottom: 10px;
}

.program-clips-list {
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 16px;

  .program {
    display: inline-block;
    margin: 2px 24px 22px 2px;
    background-color: #11011e;
    padding-bottom: 4px;
    border-radius: 10px;

    img {
      border-radius: 10px;
      width: 240px;
      height: 144px;
    }

    .program-title {
      margin-top: 8px;
      margin-bottom: 4px;
      max-width: 240px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 4px;
    }

    .program-desc {
      max-width: 240px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 4px;
    }
  }
}

.program-clips-search {
  overflow: hidden;

  .program {
    display: inline-block;
    margin: 2px 24px 22px 2px;
    background-color: #11011e;
    padding-bottom: 4px;
    border-radius: 10px;

    img {
      border-radius: 10px;
      width: 240px;
      height: 144px;
    }

    .program-title {
      margin-top: 8px;
      margin-bottom: 4px;
      max-width: 240px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 4px;
    }

    .program-desc {
      max-width: 240px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 4px;
    }
  }
}

.trailers-clips-title {
  margin-bottom: 10px;
}

.trailers-clips-list {
  overflow: hidden;
  white-space: nowrap;

  .program {
    display: inline-block;
    margin: 2px 24px 22px 2px;
    background-color: #11011e;
    padding-bottom: 4px;
    border-radius: 10px;

    img {
      border-radius: 10px;
      width: 240px;
      height: 144px;
    }

    .program-title {
      margin-top: 8px;
      margin-bottom: 4px;
      max-width: 240px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 4px;
    }

    .program-desc {
      max-width: 240px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 4px;
    }
  }
}

.broadcast-wrapper {
  position: relative;
  margin-left: 16px;
  margin-top: 28px;
  width: 336px;
  height: 189px;
}

object#broadcast-video {
  position: absolute;
  width: 336px;
  height: 189px;
  z-index: 1;
}

object#application-manager {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
}

#video-player {
  width: 336px !important;
  height: 189px !important;
}

.key-line {
  white-space: nowrap;
}

.letter {
  display: inline-block;
  background-color: #1a0130;
  margin: 6px 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 36px;
  text-align: center;

  &.selected {
    box-shadow: 0 0 0 2px #fff;
  }

  &.letter-delete,
  &.letter-space {
    width: 149px;
  }
}

.keyboard-wrapper {
  background-color: #11011e;
  width: 336px;

  .delete-text,
  .space-text {
    display: inline-block;
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

.search-bar {
  height: 46px;
  background-color: #11011e;
  line-height: 46px;
  border-radius: 10px;
  margin-bottom: 20px;

  .search-icon {
    margin-left: 20px;
  }

  .search-content {
    margin-left: 10px;
    font-weight: 600;
    display: inline-block;
  }
}

.custom-player {
  position: fixed;
  top: 0;
  left: 0;
  width: 1280px;
  height: 720px;
  z-index: 2;
}

.media-player {
  position: relative;
  width: 864px;
  height: 486px;
  overflow: hidden;
  margin-top: 234px;
  margin-left: auto;
  margin-right: auto;
  background-color: #000;

  .media-go-back {
    position: absolute;
    top: 32px;
    right: 32px;
    background-color: #1b0132b3;
    border: none;
    padding: 10px 20px;
    color: #fff;
    border-radius: 20px;
    font-size: 15px;
    text-decoration: none;
    font-weight: 500;
    visibility: hidden;
    opacity: 0;
  }
}

.media-player video {
  width: 100%;
  height: 100%;
  background-color: #000;
}

.media-player .controls {
  position: absolute;
  bottom: 16px;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 5px;
  box-sizing: border-box;
  visibility: hidden;
  opacity: 0;
}

.media-player.hover-player .controls {
  visibility: visible;
  opacity: 1;
}

.media-player .controls .progress-bar {
  height: 10px;
  width: 100%;
  background-color: #fff;
  position: relative;
  border-radius: 12px;
}

.progress-circle {
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: -6px;
  right: -6px;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
}

.media-player .controls .media-progress-wrapper.selected .progress-bar {
  background-color: #0591e2;

  .progress-circle {
    visibility: visible;
    opacity: 1;
  }
}

.media-player .controls .play-pause {
  width: 50px;
  height: 50px;
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 22px;
  text-align: center;

  i {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.media-player .controls .play-pause.selected {
  background: #fff;
  border-radius: 50%;
  color: #0591e2;
}

.media-title {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 24px;
  width: 100%;
  padding: 10px;
  visibility: hidden;
  opacity: 0;
}

.media-player.hover-player .media-title {
  visibility: visible;
  opacity: 1;
}

.media-name {
  position: absolute;
  top: 0;
  left: 10px;
  font-size: 16px;
  display: inline-block;
  padding: 6px;
  visibility: hidden;
  opacity: 0;
  background-color: #1b0132b3;
  border-radius: 4px;
  margin-top: 12px;
}

.media-player.hover-player .media-name {
  visibility: visible;
  opacity: 1;
}

.next-videos-wrapper {
  position: absolute;
  bottom: 0;
  padding: 5px;
  margin-left: 12px;
  visibility: hidden;
  opacity: 0;
}

.media-player.hover-player .next-videos-wrapper {
  visibility: visible;
  opacity: 1;
}

.news-detail-header {
  background-color: #1a0130;
  position: relative;
  z-index: 1;
  padding-bottom: 16px;

  .news-img {
    display: inline-block;
    width: 400px;
    height: auto;
    max-height: 400px;
    border-radius: 16px;
  }

  .title {
    display: inline-block;
    width: 400px;
    vertical-align: top;
  }
}

.streaming-detail-header {
  background-color: #1a0130;
  position: relative;
  z-index: 1;
  padding-bottom: 16px;

  .news-img {
    display: inline-block;
    width: 400px;
    height: 240px;
    border-radius: 16px;
  }

  .title {
    display: inline-block;
    width: 400px;
    vertical-align: top;
  }

  .start-end-time {
    margin-top: 16px;
  }
}

.next-video-list {
  white-space: nowrap;

  .next-video {
    display: inline-block;
    background-color: #1a0130;
    padding: 6px;
    border-radius: 4px;
    width: 300px;
  }

  .next-video-detail {
    display: inline-block;
    width: 58%;
    margin-left: 6px;
    white-space: normal;

    .description {
      font-size: 14px;
    }

    .title {
      font-size: 16px;
      color: #0591e2;
      margin-top: 6px;
    }
  }

  .selected {
    background: #fff;

    .description {
      color: #5c5c5c;
    }
  }
}

.next-video-img {
  width: 38%;
  float: left;
  height: 64px;
}

.font-size-20 {
  font-size: 20px;
}

.media-time {
  position: absolute;
  left: -60px;
  font-size: 20px;
  top: -7px;
}

.remaining-time {
  position: absolute;
  right: -60px;
  font-size: 20px;
  top: -7px;
}

.media-player.hover-player {
  .media-go-back {
    visibility: visible;
    opacity: 1;
  }
}

.media-back-wrapper {
  .media-go-back.selected {
    color: #0591e2;
    background-color: #fff;
  }
}

.media-progress-wrapper {
  height: 10px;
  width: 80%;
  background-color: #0003;
  position: relative;
  margin: 0 64px;
  border-radius: 12px;
}

.game-list {
  .game-item {
    display: inline-block;
    font-weight: 300;
    margin: 2px 26px 16px 2px;
    background-color: #4dc1b4;

    img {
      border-radius: 10px;
      width: 172px;
      height: 172px;
    }

    .game-title {
      margin-top: 8px;
      margin-bottom: 4px;
      margin-left: 4px;
      max-width: 172px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
    }

    &.selected {
      img {
        box-shadow: 0 0 0 2px #fff;
      }
    }
  }
}

.resize-media {
  position: absolute;
  bottom: 80px;
  right: 32px;
  background-color: #1a0130c0;
  color: #fff;
  padding: 8px 16px;
  border-radius: 16px;
  visibility: hidden;
  opacity: 0;

  &.selected {
    box-shadow: 0 0 0 2px #fff;
  }
}

.hover-player {
  .resize-media {
    visibility: visible;
    opacity: 1;
  }
}

#console-area {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1280px;
  word-wrap: break-word;
  background-color: #00000081;
  color: #fff;
  z-index: 2;
}

.left-box .currency {
  position: relative;
  overflow: hidden;
  height: 45px;
}

.currency-item {
  position: absolute;
  top: 0;
  width: 100%;
  transition: top 0.5s;

  .fa-solid {
    font-size: 20px;
    line-height: 45px;
    color: #fafafa;
    margin-right: 8px;
  }
}

.currency-item.euro {
  top: 100%;
}

.media-error-message {
  position: absolute;
  width: 100%;
  margin: auto;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
}

.mb-5 {
  margin-bottom: 5px;
}
