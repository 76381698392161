.maze-game-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 1280px;
  height: 720px;
  background-color: #ffe600;

  #canvas {
    background-color: #ffe600;
    display: block;
  }

  #maze {
    margin-top: 48px;
  }

  #wrapper {
    position: relative;
  }

  #c {
    display: inline-block;
    position: absolute;
    bottom: -48px;
    left: 48px;
    color: #000;
  }

  #timerel {
    display: inline-block;
    position: absolute;
    top: 294px;
    right: 270px;
    font-size: 36px;
    font-weight: 700;
    color: #ffe600;
    background-color: #000;
    line-height: 1;
    padding: 6px 12px;
    border-radius: 22px;
  }

  #flag {
    display: inline-block;
    position: absolute;
    bottom: -42px;
    left: 493px;
  }

  .maze-game-back {
    position: absolute;
    top: 0;
    right: 32px;
    color: #000;
    font-weight: 600;
    font-size: 18px;

    i {
      color: #00f;
      margin-right: 6px;
    }
  }

  .maze-game-restart {
    position: absolute;
    top: 36px;
    right: 32px;
    color: #000;
    font-weight: 600;
    font-size: 18px;

    i {
      color: #080;
      margin-right: 6px;
    }
  }

  .result-elem {
    position: absolute;
    top: 240px;
    right: 230px;
    font-weight: 600;
    color: #000;
    font-size: 32px;
    font-style: italic;
  }
}
